import React, { useMemo } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { TKeyValue } from '@Models/App/types';
import reactSessionStorage from '@Helper/sessionStorage';
import { isEmpty } from 'lodash';
import reactLocalStorage from '@Helper/localStorage';

export const ProtectedRoute = ({
  component: Component,
  context,
  ...rest
}: any) => {
  // const { cart } = useContext(AppContext);
  const currentCart = reactSessionStorage.getObject('current-cart');
  const currentRestaurant = reactLocalStorage.getObject('current-restaurant');
  const orderHistorySelectedItem = reactLocalStorage.getObject(
    'order-history-selected-order',
  );
  const paymentConfirmData: TKeyValue = reactSessionStorage.getObject(
    'payment-confirm-data',
  );

  const TOCandPPRounter =
    reactSessionStorage.get('TOC-and-PP', false) === 'true';
  const isConditionChecked = useMemo(() => {
    switch (context) {
      case 'checkout':
        return !isEmpty(currentCart) || !isEmpty(orderHistorySelectedItem);
      case 'payment-confirmation':
        return !isEmpty(paymentConfirmData) || TOCandPPRounter;
      case 'qr-confirmation':
        return !isEmpty(paymentConfirmData) || TOCandPPRounter;
      default:
        return false;
    }
  }, [
    context,
    currentCart,
    paymentConfirmData,
    TOCandPPRounter,
    orderHistorySelectedItem,
  ]);
  const redirectLink = useMemo(() => {
    return isEmpty(currentRestaurant)
      ? '/'
      : `/restaurant/${currentRestaurant.restid}`;
  }, [currentRestaurant]);
  return (
    <Route
      {...rest}
      render={(props) =>
        isConditionChecked ? (
          <Component {...props} />
        ) : (
          <Redirect to={redirectLink} />
        )
      }
    />
  );
};
