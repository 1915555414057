import { Navigation } from '@Models/Navigation/interfaces';

const DEFAULT_NAVIGATION_CONFIG = {
  backUrl: '/',
  class: 'has-background-white',
  titleClass: 'has-text-black',
  backUrlClass: 'has-text-link',
  navBarMenuButtonClass: 'has-text-link',
  isShowCart: true,
};

export const NAVIGATION: Navigation = {
  menus: [
    {
      id: 1,
      link: '/',
      text: 'Home',
      icon: 'fas fa-home',
      badge: undefined,
      requiredAuth: false,
    },
    {
      id: 2,
      link: '/account',
      text: 'Account',
      icon: 'fas fa-user',
      badge: undefined,
      requiredAuth: true,
    },
    {
      id: 3,
      link: '/order-history',
      text: 'Orders history',
      icon: 'fas fa-history',
      badge: undefined,
      requiredAuth: true,
    },
    // {
    //   id: 4,
    //   link: '/near-by',
    //   text: 'Nearby',
    //   badge: undefined,
    //   requiredAuth: false,
    // },
    {
      id: 5,
      link: '/privacy',
      text: 'Privacy Policy',
      badge: undefined,
      requiredAuth: false,
    },
    {
      id: 6,
      link: '/terms',
      text: 'Terms of use',
      badge: undefined,
      requiredAuth: false,
    },
  ],
  pages: {
    home: {
      title: 'Home',
      class: 'has-background-light',
      titleClass: 'has-text-grey-light',
      backUrlClass: 'has-text-link',
      navBarMenuButtonClass: 'has-text-link',
      isShowQrCodeMenuButton: false,
      isShowCart: true,
    },
    homerest: {
      backUrl: '/',
      class: 'has-background-dark',
      titleClass: 'has-text-white',
      backUrlClass: 'has-text-white',
      navBarMenuButtonClass: 'has-text-light',
      isShowCart: true,
    },
    restaurant: {
      backUrl: '/',
      class: 'has-background-dark',
      titleClass: 'has-text-white',
      backUrlClass: 'has-text-white',
      navBarMenuButtonClass: 'has-text-light',
      isShowCart: true,
    },
    signup: {
      backUrl: '/',
      title: 'Sign Up',
      backUrlClass: 'has-text-link',
      titleClass: '',
      class: '',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: false,
    },
    activation: {
      backUrl: '/',
      title: 'Activation',
      backUrlClass: 'has-text-white',
      titleClass: 'has-text-white',
      class: 'has-background-success',
      navBarMenuButtonClass: 'has-text-white',
      isShowCart: false,
    },
    signin: {
      backUrl: '/',
      title: 'Log In',
      backUrlClass: 'has-text-link',
      class: '',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: false,
    },
    category: {
      backUrl: '/',
      title: 'Main Menus',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      backUrlClass: 'has-text-link',
      isShowCart: true,
      isShowQrCodeMenuButton: false,
    },
    subcategory: {
      backUrl: '/',
      title: 'Sub Menus',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      backUrlClass: 'has-text-link',
      isShowCart: true,
      isShowQrCodeMenuButton: false,
    },
    items: {
      backUrl: '/',
      title: 'Menu items list',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      backUrlClass: 'has-text-link',
      isShowCart: true,
    },
    item: {
      backUrl: '/',
      title: '',
      class: 'has-background-light',
      navBarMenuButtonClass: 'has-text-link',
      backUrlClass: 'has-text-link',
      isShowCart: true,
    },
    cart: {
      backUrl: '/',
      title: 'Cart',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: false,
    },
    inshop: {
      backUrl: '/',
      title: 'In-Shop',
      class: 'has-background-dark',
      backUrlClass: 'has-text-white',
      navBarMenuButtonClass: 'has-text-light',
      titleClass: 'has-text-light',
      isShowCart: true,
      isShowInShopMenuButton: true,
    },
    delivery: {
      backUrl: '/',
      title: 'Delivery',
      class: 'has-background-dark',
      backUrlClass: 'has-text-white',
      navBarMenuButtonClass: 'has-text-light',
      titleClass: 'has-text-light',
      isShowCart: true,
      isShowInShopMenuButton: true,
    },
    'take-away': {
      backUrl: '/',
      title: 'Self-Collect',
      class: 'has-background-dark',
      backUrlClass: 'has-text-white',
      navBarMenuButtonClass: 'has-text-light',
      titleClass: 'has-text-light',
      isShowCart: true,
      isShowInShopMenuButton: true,
    },
    information: {
      backUrl: '/',
      title: 'Information',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    history_loyalty: {
      backUrl: '/',
      title: 'Loyalty Points',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    history_wallet: {
      backUrl: '/',
      title: 'Wallet',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    account: {
      backUrl: '/',
      title: 'Account',
      class: 'has-background-white',
      titleClass: 'has-text-black',
      backUrlClass: 'has-text-link',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    reservation: {
      backUrl: '/',
      title: 'Reservation',
      class: 'has-background-white',
      titleClass: 'has-text-black',
      backUrlClass: 'has-text-link',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    deactivate: {
      backUrl: '/',
      title: 'Account Deletion',
      class: 'has-background-white',
      titleClass: 'has-text-black',
      backUrlClass: 'has-text-link',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    'reset-password': {
      backUrl: '/',
      title: 'Reset Password',
      class: 'has-background-white',
      titleClass: 'has-text-black',
      backUrlClass: 'has-text-link',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    'forgot-password': {
      backUrl: '/',
      title: 'Recovery',
      class: 'has-background-white',
      titleClass: 'has-text-black',
      backUrlClass: 'has-text-link',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    'payment-stripe': {
      backUrl: '/',
      backUrlClass: 'is-hidden',
      title: 'Invoice',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: false,
    },
    payment: {
      backUrl: '/',
      title: 'Payment',
      class: 'has-background-white',
      titleClass: 'has-text-black',
      backUrlClass: 'has-text-link',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    privacy: {
      ...DEFAULT_NAVIGATION_CONFIG,
      title: 'Privacy Policy',
    },
    terms: {
      ...DEFAULT_NAVIGATION_CONFIG,
      title: 'Terms of use',
    },
    'payment-confirmation': {
      backUrl: '/',
      backUrlClass: 'is-hidden',
      title: 'Payment',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: false,
    },
    'order-history': {
      backUrl: '/',
      title: 'Orders history',
      class: 'has-background-light',
      backUrlClass: 'has-text-link',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: true,
    },
    'qr-confirmation': {
      backUrl: '/',
      backUrlClass: 'is-hidden',
      title: 'Payment',
      class: 'has-background-white',
      navBarMenuButtonClass: 'has-text-link',
      isShowCart: false,
    },
  },
};
