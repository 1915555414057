export function onFullfilled(response: any) {
  return Promise.resolve(response);
}

function isNetworkError(err: any) {
  return !!err.isAxiosError && !err.response;
}

export function onRejected(error: any) {
  if (error) {
    if (!isNetworkError(error)) {
      const { response } = error;
      return Promise.reject(response && response.data);
    } else {
      return Promise.reject('NETWORK_ERROR');
    }
  }
  return Promise.reject();
}
