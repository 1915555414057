import reactLocalStorage from './localStorage';
import { isEmpty } from 'lodash';

export function getOutletNameByMenuId(menuId: number) {
  const keyCategory = 'restaurant-category-' + menuId;
  const _category = reactLocalStorage.getObject(keyCategory);
  if (!_category || Object.keys(_category).length <= 0) return '';
  const keyOutlet = 'restaurant-outlet-' + _category[0].restaurantId;
  const _outlet = reactLocalStorage.getObject(keyOutlet);
  if (!_outlet) return '';
  const { outletMenus } = reactLocalStorage.getObject(
    _category[0].restaurantGlobalId,
  );
  if (!outletMenus) return '';
  // @ts-ignore
  const outletMenu = outletMenus.find((f) => f.menuId === menuId);
  const outletId = outletMenu ? outletMenu.outletId : -1;
  // @ts-ignore
  const outlet = _outlet.find((f) => f.id === outletId);
  return outlet ? outlet.name : '';
}

export function getOutletNameByMenuIdAndCategoryId(
  menuId: number,
  categoryId: number,
) {
  const keyCategory = 'restaurant-category-' + menuId;
  const categorys = reactLocalStorage.getObject(keyCategory);
  if (!categorys) return '';
  // @ts-ignore
  const category = categorys && categorys.find((f) => f.id === categoryId);
  return category ? category.name : '';
}

export function getOutletNameByMenuIdAndSubCategoryId(
  menuId: number,
  subcategoryId: number,
  availableCategoryId: number,
) {
  const key = `restaurant-subcategory-${menuId}-${subcategoryId}`;

  const subCategories = reactLocalStorage.getObject(key);
  if (!subCategories || isEmpty(subCategories)) return '';
  // @ts-ignore
  const subcategory = subCategories.find((f) => f.id === availableCategoryId);
  return subcategory ? subcategory.name : '';
}

export function getTitle(): string {
  return reactLocalStorage.get('navigation-bar-title', '');
}

export function setTitle(title: string): string {
  return reactLocalStorage.set('navigation-bar-title', title) || '';
}
