export const OUTLET_CATEGORY = {
  SITIN: 'sitIn',
  TAKE_AWAY: 'takeAway',
  DELIVERY: 'delivery',
  HOTEL: 'hotel',
  ARCHIVED: 'archived',
  IN_SHOP: 'inshop',
};

export const OUTLET_NAME = {
  TAKE_AWAY: 'Self-Collect',
  DELIVERY: 'Delivery',
  RESERVATION: 'Reservation',
  IN_SHOP: 'In-Shop',
  BROWSE_MENU: 'Browse Menu',
};

export const LOCALES = {
  en_US: 'en_US',
  vi_VN: 'vi_VN',
  fr_FR: 'fr_FR',
  ms_MY: 'ms_MY',
};

export const GOOGLE_API_KEY = 'AIzaSyAyi561p9YMsv9Zut_SL_FD47vFLjJ2-i8';

export const MERCHANT_ID = process.env.REACT_APP_MASTER_CARD_MERCHANT_ID || '';

export const MASTER_CARD_URL = process.env.REACT_APP_MASTER_CARD_URL || '';

export const THREE_DS_URL =
  process.env.REACT_APP_MASTER_CARD_THREE_DS_URL || '';

export const ADYEN_CLIENT_KEY = 'test_DHIP44DKFJCJJMCC6QCDE4WLDYWHDI4G';
