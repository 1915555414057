import React, { useContext } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { translations } from '@Locales/i18n';
import { destroyUserCredentials } from '../../helper/userLocalStorage';
import { Customer } from '@Models/Customer/interfaces';
import reactSessionStorage from '../../helper/sessionStorage';
import { AppContext } from '../../AppContext';
import { useTranslation } from 'react-i18next';
import reactLocalStorage from '@Helper/localStorage';

const NavigationControls: React.FC<{
  isAuth: boolean;
  closeNavigation: () => void;
}> = ({ isAuth, closeNavigation }) => {
  const { currentRestaurant, setLoggedInCustomer, cartClear } = useContext(
    AppContext,
  );

  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();
  const { showNotification } = useContext(AppContext);

  const clearData = () => {
    closeNavigation();
    destroyUserCredentials();
    cartClear();
    const nextCustomer: Customer = {};
    setLoggedInCustomer(nextCustomer);
    reactSessionStorage.clear();
    reactLocalStorage.clear();
    showNotification(
      3000,
      'fas fa-sign-out-alt',
      t(translations.Sidemenu.ntnLogoutSuccess),
      'is-info',
    );
  };

  const logout = () => {
    const globalId = (currentRestaurant && currentRestaurant.globalId) || '';
    const currentRestaurantStorage = reactLocalStorage.getObject(
      'current-restaurant',
    );
    clearData();
    if (location.pathname.indexOf('restaurant') !== -1) {
      window.location.reload();
    } else {
      if (globalId) {
        reactLocalStorage.setObject(
          'current-restaurant',
          currentRestaurantStorage,
        );
        history.replace({
          pathname: `/restaurant/${globalId}`,
          state: null,
        });
      } else {
        history.replace({
          pathname: '/',
          state: null,
        });
      }
    }
  };

  const signup = () => {
    closeNavigation();
    reactSessionStorage.setObject('returnurl-for-activation', {
      pathname: location.pathname,
      search: location.search,
    });
  };
  return (
    <div className="navbar-end">
      <div className="navbar-item">
        <div className="buttons navigation-controls">
          {isAuth ? (
            <button className="button is-danger" onClick={logout}>
              <strong>{t(translations.Sidemenu.btnLogout)}</strong>
            </button>
          ) : (
            <>
              <Link to="/signup" className="button is-primary" onClick={signup}>
                <strong>{t(translations.Sidemenu.btnSignup)}</strong>
              </Link>
              <Link
                to="/signin"
                className="button is-info"
                onClick={closeNavigation}
              >
                <strong>{t(translations.Sidemenu.btnLogin)}</strong>
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NavigationControls;
