import { VERSION_NUMBER } from '@Constants/common';
import reactSessionStorage from '@Helper/sessionStorage';
import { translations } from '@Locales/i18n';
import React, { useState, useMemo, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { NAVIGATION } from '../../constants';
import { NavigationPageData } from '@Models/Navigation/interfaces';
import { AppContext } from '../../AppContext';
import NavigationMenus from './NavigationMenus';
import NavigationControls from './NavigationControls';
import './NavBar.scss';
import logo from '../../assets/images/restologia-icon.png';
import reactLocalStorage from '@Helper/localStorage';
import { menuMap } from '@Helper/id';
import { useTranslation } from 'react-i18next';
function getCurrentPage(pathname: string, queryParams: string): string {
  if (pathname === '/' || !pathname) {
    return 'home';
  } else if (pathname === '/order-history') {
    return pathname.split('/')[1];
  } else if (pathname.includes('category') && pathname.split('/').length > 2) {
    return pathname.split('/')[1];
  } else if (pathname.includes('history')) {
    const pathnames = pathname.split('/');
    return `${pathnames[1]}_${pathnames[2]}`;
  } else {
    return pathname.split('/')[1];
  }
}

function getNavData(currentPage: string): NavigationPageData {
  // @ts-ignore
  return NAVIGATION.pages[currentPage];
}

interface NavProps {
  currentPage?: string;
  onBack?: () => void;
}

function firstLetterUpper(theString: string) {
  const newString = theString
    .toLowerCase()
    .replace(/(^\s*\w|[.!?]\s*\w)/g, function (c) {
      return c.toUpperCase();
    });
  return newString;
}

const NavBar: React.FC<NavProps> = ({ currentPage, onBack }) => {
  const { navBarTitle, customer, cart, currentRestaurant } = useContext(
    AppContext,
  );

  const [navPageData, setNavPageData] = useState<NavigationPageData>(
    () => NAVIGATION.pages.home,
  );
  const [navbarActive, setNavbarActive] = useState<boolean>(false);
  const history = useHistory();
  const location = useLocation();
  const className = useMemo(
    () => classNames('navbar', navPageData?.class ?? '', 'navbar is-fixed-top'),
    [navPageData],
  );
  const isAuth = useMemo(() => {
    return !!(customer && customer.id);
  }, [customer]);
  const currentCategoryPageMetadata = reactLocalStorage.getObject(
    'current-category-page',
  );
  const TOCandPPRounter =
    reactSessionStorage.get('TOC-and-PP', false) === 'true';
  const { t } = useTranslation();
  const backItem = useMemo(() => {
    if (
      location.pathname.slice(1, 11) === 'restaurant' &&
      currentRestaurant.privateOnline
    ) {
      return <></>;
    }

    return (
      navPageData &&
      navPageData.backUrl && (
        <button
          onClick={(e) => {
            e.preventDefault();
            if (TOCandPPRounter) {
              reactSessionStorage.set('TOC-and-PP', false);
            }
            if (typeof onBack === 'function') {
              onBack();
            } else {
              if (location.pathname.slice(1, 11) === 'restaurant') {
                history.push('/');
              } else {
                history.goBack();
              }
            }
          }}
          className={`navbar-item navbar-back-btn ${navPageData.backUrlClass}`}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
      )
    );
  }, [navPageData, history, location, onBack, currentRestaurant]);
  const title = () => {
    return (
      <h1
        className={`navbar-item has-text-weight-bold is-size-4 ${
          navPageData && navPageData.titleClass
        }`}
      >
        {(navBarTitle && firstLetterUpper(navBarTitle)) ||
          (navPageData && navPageData.title) ||
          ''}
      </h1>
    );
  };
  const cartMenuItem = useMemo(() => {
    return (
      cart &&
      !!cart.length &&
      navPageData &&
      navPageData.isShowCart &&
      !TOCandPPRounter && (
        <Link
          to="/cart"
          onClick={(e) => {
            if (currentPage === 'item' && typeof onBack === 'function') {
              onBack();
              e.preventDefault();
            }
          }}
          className={`navbar-item cart-icon pr-4 ${navPageData.backUrlClass}`}
        >
          <i className="fas fa-shopping-cart" />
          {cart && !!cart.length && <span className="cart-badge" />}
        </Link>
      )
    );
  }, [navPageData, cart, currentPage, onBack, TOCandPPRounter]);
  const qrcodeMenuItem = useMemo(() => {
    const isShowQrCodeIcon =
      (location.pathname.indexOf('/category') !== -1 ||
        location.pathname.indexOf('/subcategory') !== -1 ||
        location.pathname.indexOf('/items') !== -1 ||
        location.pathname.indexOf('/cart') !== -1) &&
      currentCategoryPageMetadata.enableQrCode;
    return (
      isShowQrCodeIcon && (
        <Link
          to={currentCategoryPageMetadata.path}
          className="navbar-item has-text-link"
        >
          <i className="fas fa-qrcode" />
        </Link>
      )
    );
  }, [currentCategoryPageMetadata, location]);
  const menuItem = useMemo(() => {
    return (
      navPageData &&
      navPageData.isShowInShopMenuButton &&
      !TOCandPPRounter && (
        <Link
          to={
            '/category/' +
            menuMap.getEncoded(currentCategoryPageMetadata?.menuId)
          }
          className="navbar-item has-text-light pr-4"
        >
          <i className="fas fa-book-open" />
        </Link>
      )
    );
  }, [navPageData, currentCategoryPageMetadata]);

  useEffect(() => {
    if (currentPage) {
      setNavPageData(getNavData(currentPage));
    } else {
      const currentPageFromUrl = getCurrentPage(
        location.pathname,
        location.search,
      );
      setNavPageData(getNavData(currentPageFromUrl));
    }
    closeNavigation();
  }, [location.pathname, location.search, currentPage]);

  const closeNavigation = () => {
    setNavbarActive(() => false);
  };

  return (
    <OutsideClickHandler onOutsideClick={closeNavigation}>
      <nav className={className} role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          {(location.pathname === '/' || location.pathname === '/near-by') &&
            !location.search && (
              <Link to="/" className="navbar-item">
                <img src={logo} width="auto" height="80" alt="logo" />
              </Link>
            )}
          {backItem}
          {title()}

          {!Boolean(TOCandPPRounter) && (
            <a
              href="/#"
              role="button"
              className={classNames(
                'navbar-burger burger',
                navPageData && navPageData.navBarMenuButtonClass,
                {
                  'is-active': navbarActive,
                },
              )}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={(e) => {
                e.preventDefault();
                setNavbarActive(!navbarActive);
              }}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </a>
          )}
          {qrcodeMenuItem}
          {cartMenuItem}
          {menuItem}
        </div>
        <div
          id="navbarBasicExample"
          className={classNames('navbar-menu is-boxed', {
            'is-active': navbarActive,
          })}
        >
          <NavigationMenus isAuth={isAuth} closeNavigation={closeNavigation} />
          <NavigationControls
            isAuth={isAuth}
            closeNavigation={closeNavigation}
          />

          <span className="navbar-item has-text-grey-light has-text-centered is-size-7">
            {t(translations.Sidemenu.lblVersion)} {VERSION_NUMBER}
          </span>
        </div>
      </nav>
    </OutsideClickHandler>
  );
};

export default NavBar;
