import React, { Suspense, useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Home from '@Containers/Home/Loadable';
import LandingPage from '@Containers/LangdingPage/Loadable';
import About from '@Containers/About/Loadable';
import Signup from '@Containers/Signup/Loadable';
import Category from '@Containers/Category/Loadable';
import SubCategory from '@Containers/SubCategory/Loadable';
import SignIn from '@Containers/Signin/Loadable';
import Activation from '@Containers/Activation/Loadable';
import Item from '@Containers/ItemsList/Loadable';
import Cart from '@Containers/Cart/Loadable';
import Information from '@Containers/Information/Loadable';
import Privacy from '@Containers/Privacy/Loadable';
import Terms from '@Containers/Terms/Loadable';
import History from '@Containers/History/Loadable';
import Account from '@Containers/Account/Loadable';
import Reservation from '@Containers/Reservation/Loadable';
import ResetPassword from '@Containers/ResetPassword/Loadable';
import AccountDeletion from '@Containers/AccountDeletion/Loadable';
import ForgotPassword from '@Containers/ForgotPassword/Loadable';
import Inshop from '@Containers/Inshop/Loadable';
import Delivery from '@Containers/Delivery/Loadable';
import TakeAway from '@Containers/TakeAway/Loadable';
import OrderHistory from '@Containers/OrderHistory/Loadable';
import Payment from '@Containers/Payment/Loadable';
import CheckoutForm from '@Containers/Checkout/Loadable';
import PaymentConfirmation from '@Containers/PaymentConfirmation/Loadable';
import QrConfirmation from '@Containers/QrConfirmation/Loadable';
import Map from '@Containers/Map/Loadable';
import NavBar from '@Components/NavBar';
import { PrivateRoute } from '@Components/PrivateRoute';
import { GuestRoute } from '@Components/GuestRoute';
import { ProtectedRoute } from '@Components/ProtectedRoute';
import { AppContext } from './AppContext';
import { translations } from '@Locales/i18n';

import './App.scss';

const App: React.FC = () => {
  const { showNotification } = useContext(AppContext);
  const { t } = useTranslation();

  const redirectToHome = () => {
    showNotification(
      3000,
      'fas fa-exclamation-circle',
      t(translations.Global.ERR_PAGE_NOT_FOUND),
      'is-warning',
    );

    return <Redirect to="/" />;
  };
  return (
    <Router>
      <Suspense fallback={<div />}>
        <NavBar />
        <div className="wrapper">
          <Switch>
            <Route exact path="/about" component={About} />
            <Route exact path="/reservation" component={Reservation} />
            <GuestRoute exact path="/signup" component={Signup} />
            <Route exact path="/activation" component={Activation} />
            <GuestRoute exact path="/signin" component={SignIn} />
            <Route exact path="/category/:id" component={Category} />
            <Route
              exact
              path="/subcategory/:cate_id/:id"
              component={SubCategory}
            />
            <Route exact path="/items/:cate_id/:id" component={Item} />
            <ProtectedRoute
              exact
              path="/payment"
              component={Payment}
              context="checkout"
            />
            <ProtectedRoute
              exact
              path="/cart"
              component={Cart}
              context="checkout"
            />
            <Route exact path="/inshop" component={Inshop} />
            <Route exact path="/take-away" component={TakeAway} />
            <Route exact path="/delivery" component={Delivery} />
            <PrivateRoute
              exact
              path="/order-history"
              component={OrderHistory}
            />
            <Route exact path="/payment-stripe" component={CheckoutForm} />
            <ProtectedRoute
              exact
              path="/payment-confirmation"
              component={PaymentConfirmation}
              context="payment-confirmation"
            />
            <ProtectedRoute
              exact
              path="/qr-confirmation"
              component={QrConfirmation}
              context="qr-confirmation"
            />
            <ProtectedRoute
              exact
              path="/information"
              component={Information}
              context="checkout"
            />
            <Route exact path="/history/:type/:rest_id" component={History} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/near-by" component={Map} />

            <PrivateRoute exact path="/account" component={Account} />
            <Route exact path="/reset-password" component={ResetPassword} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <PrivateRoute
              exact
              path="/deactivate"
              component={AccountDeletion}
            />
            <Route exact path="/" component={Home} />
            <Route exact path="/restaurant/:restid" component={LandingPage} />
            <Route render={() => redirectToHome()} />
          </Switch>
        </div>
      </Suspense>
    </Router>
  );
};

export default App;
