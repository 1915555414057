import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { loadUserCredentials } from '../../helper/userLocalStorage';

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const auth = loadUserCredentials();
  return (
    <Route
      {...rest}
      render={(props) =>
        auth && auth.token ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};
