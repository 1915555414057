const reactLocalStorage = {
  set(key: string, value: any) {
    localStorage[key] = value;
    return localStorage[key];
  },
  get(key: string, defaultValue: any) {
    return localStorage[key] || defaultValue;
  },
  setObject(key: string, value: any) {
    localStorage[key] = JSON.stringify(value);
    return localStorage[key];
  },
  getObject(key: string) {
    return JSON.parse(localStorage[key] || '{}');
  },
  clear() {
    return localStorage.clear();
  },
  delete(key: string) {
    delete localStorage[key];
  },
};

export default reactLocalStorage;
