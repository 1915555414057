import { customRandom, urlAlphabet } from 'nanoid';

// @ts-ignore
const crypt = window.crypto;
const nanoid = customRandom(urlAlphabet, 10, (size) =>
  crypt.getRandomValues(new Uint8Array(size)),
);

function createIdMap() {
  const newIdMap = new Map();

  function saveId(id: number | string) {
    if (!newIdMap.has(id)) {
      const generatedId = nanoid();
      newIdMap.set(id, generatedId);
      newIdMap.set(generatedId, id);
    }
  }

  function get(key: any): any {
    return newIdMap.get(key);
  }

  return {
    getMap() {
      return newIdMap;
    },
    get,
    getId: get,
    getEncoded: get,
    has: (key: any): boolean => newIdMap.has(key),

    clear() {
      newIdMap.clear();
    },

    saveId,
    saveIds(ids: (number | string)[]) {
      ids.forEach(saveId);
    },
  };
}

export const menuMap = createIdMap();
export const categoryMap = createIdMap();
export const subCategoryMap = createIdMap();

export function clearAllIds() {
  menuMap.clear();
  categoryMap.clear();
  subCategoryMap.clear();
}
