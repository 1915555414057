import { TKeyValue } from '@Models/App/types';
import { encode, decode } from 'js-base64';
const keyUsername = 'keyUsername';
const keyPassword = 'keyPassword';
const localTokenKey = 'localTokenKey';
const localCustomerKey = 'localCustomerKey';

export function loadUserCredentials(): TKeyValue | undefined {
  const usernameKey = encode(keyUsername);
  const passwordKey = encode(keyPassword);
  const token = window.localStorage.getItem(localTokenKey);
  const customer = window.localStorage.getItem(localCustomerKey);
  const encodedUsername = window.localStorage.getItem(usernameKey);
  const encodedPassword = window.localStorage.getItem(passwordKey);

  let result = {};
  if (customer) {
    result = { ...result, customer: JSON.parse(customer) };
  }
  if (token) {
    result = { ...result, token };
  }
  if (encodedUsername) {
    result = { ...result, username: decode(encodedUsername) };
  }
  if (encodedPassword) {
    result = { ...result, password: decode(encodedPassword) };
  }

  return Object.keys(result).length > 0 ? result : undefined;
}

export function storeUserCredentials(
  customer: any,
  token?: string,
  username?: string,
  password?: string,
) {
  window.localStorage.setItem(localCustomerKey, JSON.stringify(customer));
  if (token) {
    window.localStorage.setItem(localTokenKey, token);
  }

  const usernameKey = encode(keyUsername);
  const passwordKey = encode(keyPassword);

  if (username && password) {
    const encodedUsername = encode(username);
    window.localStorage.setItem(usernameKey, encodedUsername);

    const encodedPass = encode(password);
    window.localStorage.setItem(passwordKey, encodedPass);
  } else {
    window.localStorage.removeItem(usernameKey);
    window.localStorage.removeItem(passwordKey);
  }
}

export function destroyUserCredentials() {
  window.localStorage.removeItem(localTokenKey);
  window.localStorage.removeItem(localCustomerKey);

  const usernameKey = encode(keyUsername);
  const passwordKey = encode(keyPassword);
  window.localStorage.removeItem(usernameKey);
  window.localStorage.removeItem(passwordKey);
}
