function set(key: string, value: any) {
  sessionStorage.setItem(key, value);
  return get(key);
}
function get(key: string, defaultValue?: any) {
  return sessionStorage.getItem(key) || defaultValue;
}
function setObject(key: string, value: any) {
  sessionStorage.setItem(key, JSON.stringify(value));
  return getObject(key);
}
function getObject(key: string) {
  return JSON.parse(sessionStorage.getItem(key) || '{}');
}
function clear() {
  return sessionStorage.clear();
}
function remove(key: string) {
  return sessionStorage.removeItem(key);
}

const reactSessionStorage = {
  set,
  get,
  setObject,
  getObject,
  clear,
  remove,
};

export default reactSessionStorage;
