import React from 'react';
import { Route, useHistory } from 'react-router-dom';

import { loadUserCredentials } from '../../helper/userLocalStorage';

export const GuestRoute = ({ component: Component, ...rest }: any) => {
  const auth = loadUserCredentials();
  const history = useHistory();
  if (auth && auth.token) {
    history.goBack();
  }
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};
