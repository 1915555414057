import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useTranslation } from 'react-i18next';
import { translations } from '@Locales/i18n';
import classnames from 'classnames';
import { Notification } from '@Models/App/interfaces';
import './Notification.scss';

interface IProps {
  notification: Notification;
  onSwipe?: () => void;
}
const NotificationItem: React.FC<IProps> = ({ notification, onSwipe }) => {
  const [notiClass, setNotiClass] = useState<string>('');
  const addClassOnSwipe = (classname: string) => {
    setNotiClass(classname);
    if (onSwipe) {
      onSwipe();
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => addClassOnSwipe('fadeOutLeft'),
    onSwipedRight: () => addClassOnSwipe('fadeOutRight'),
  });

  const renderConfirmNotification = () => {
    if (notification.confirm) {
      return (
        <div
          {...handlers}
          className={classnames(
            notiClass,
            'nice-notification columns animated',
            notification.type,
            'is-mobile is-2',
            { shadow: !notification.overlay },
          )}
        >
          <div className="column is-narrow">
            <i className={notification.icon}></i>
          </div>
          <div className="column has-background-white has-text-centered content">
            <p className="my-3">{notification.message}</p>

            {!notification.confirm.standard && (
              <>
                <button
                  onClick={notification.confirm.onContinue}
                  className="button is-fullwidth is-link is-rounded my-3"
                >
                  <span>{notification.confirm.continueText}</span>
                  <span className="icon">
                    <i className="fas fa-arrow-right"></i>
                  </span>
                </button>

                {notification.confirm.onCancel && (
                  <button
                    onClick={notification.confirm.onCancel}
                    className="button is-fullwidth is-rounded is-danger is-outlined cancel-btn"
                  >
                    {notification.confirm.hideTrashIcon || (
                      <span className="icon">
                        <i className="fas fa-trash-alt"></i>
                      </span>
                    )}
                    <span>{notification.confirm.cancelText}</span>
                  </button>
                )}
              </>
            )}

            {notification.confirm.standard && (
              <div className="flex justify-end">
                <button
                  onClick={notification.confirm.onCancel}
                  className="button is-rounded is-danger is-outlined"
                >
                  {notification.confirm.cancelText}
                </button>

                <button
                  onClick={notification.confirm.onContinue}
                  className="button ml-3 is-link is-rounded"
                >
                  {notification.confirm.continueText}
                </button>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return (
        <div
          {...handlers}
          className={classnames(
            notiClass,
            'items-stretch',
            'nice-notification columns animated',
            notification.type,
            'is-vcentered',
            { shadow: !notification.overlay },
          )}
        >
          <div className="column">
            <i className={notification.icon}></i>
          </div>
          <div className="column is-size-7 notification-body-content notification-with-title">
            {notification.title && (
              <>
                <div className="has-text-weight-bold mb-2">
                  {notification.title}
                </div>
              </>
            )}
            {notification.message}
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {notification.overlay && <div className="notification-overlay"></div>}
      {renderConfirmNotification()}
    </>
  );
};

export const LoadingIndicator: React.FC = () => {
  const { t } = useTranslation();
  const loadingNoti = {
    icon: 'fa fa-spinner fa-pulse fa-fw',
    message: t(translations.Global.retrievingData),
    type: 'is-info',
  };

  return <NotificationItem notification={loadingNoti} />;
};

export default NotificationItem;
