export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const BASE_URL = `${API_BASE_URL}/ws/crm`;

// User
export const USER_URL = BASE_URL + '/user';
export const ADD_OR_UPDATE_CUSTOMER_ADDRESS =
  USER_URL + '/addOrUpdateCustomerAddress';
export const MAKE_CUSTOMER_ADDRESS_DEFAULT =
  USER_URL + '/makeCustomerAddressDefault';
export const DELETE_CUSTOMER_ADDRESS = USER_URL + '/deleteCustomerAddress';
export const CHANGE_FOLLOWING_URL = BASE_URL + '/following/changeFollowing';

// Auth
export const LOGIN_URL = USER_URL + '/login';
export const SIGNUP_URL = USER_URL + '/addUpdateCustomer';
export const SEND_ACTIVATION_CODE_URL = USER_URL + '/sendActivationCode';
export const ACTIVATE_CUSTOMER_URL = USER_URL + '/activateCustomer';
export const CUSTOMER_URL = USER_URL + '/getCustomerByLoginOrigin';
export const RECOVER_PASSWORD_URL = USER_URL + '/recoverPassword';
export const GET_CUSTOMER_URL = BASE_URL + '/secure/user/getCustomer';

//Account
export const UPDATE_CUSTOMER_URL = USER_URL + '/updateCustomerProfile';
export const SEND_RESET_PASS_ACTIVATION_CODE_URL =
  USER_URL + '/sendPasswordResetCode';
export const RESET_PASSWORD = USER_URL + '/resetPassword';
export const SEND_DEACTIVATION_CODE_URL = USER_URL + '/sendDeactivationCode';
export const DEACTIVATE_CUSTOMER_URL = USER_URL + '/deactivateCustomer';
export const BECOME_MEMBER_URL = USER_URL + '/becomeMemberOfRestaurant';
export const STOP_MEMBER_URL = USER_URL + '/stopMemberOfRestaurant';

// Restaurant
export const RESTAURANT_URL = BASE_URL + '/restaurant';
export const RESTAURANT_DETAIL_URL = RESTAURANT_URL + '/getRestaurant';
export const SEARCH_RESTAURANT_URL = RESTAURANT_URL + '/search';
export const SEARCH_NEARBY_RESTAURANT_URL = RESTAURANT_URL + '/searchNearby';
export const SEARCH_FOLLOWED_RESTAURANT_URL =
  RESTAURANT_URL + '/searchFollowedRestaurants';
export const GET_LIST_RESTAURANT_URL =
  RESTAURANT_URL + '/getRestaurantsBySlice';

export const REFERENTIAL_DATA_URL = RESTAURANT_URL + '/getReferentialDatas';
export const TAXES_URL = RESTAURANT_URL + '/getTaxes';
export const COUNTRIES_URL = USER_URL + '/getCountries';

// Outlet
export const OUTLET_URL = BASE_URL + '/outlet';
export const OUTLET_LIST_URL = OUTLET_URL + '/getOutletsByRestaurant';
export const OUTLET_DETAIL = OUTLET_URL + '/getOutlet';

// Menu
export const MENU_URL = BASE_URL + '/menu';
export const MENU_LIST_URL = MENU_URL + '/getMenusByRestaurant';

// Fnb
export const FNB_URL = BASE_URL + '/fnb';
export const FNB_LIST_URL = FNB_URL + '/getFnbsByCategoryAndMenu';
export const MENU_FNB = FNB_URL + '/getMenuFnbWithIdOrBarcode';
export const FNB_SEARCH = FNB_URL + '/searchFnb';

// Category
export const CATEGORY_URL = BASE_URL + '/fnbcat';
export const CATEGORY_LIST_URL = CATEGORY_URL + '/getRootFnbCategoriesByMenu';

// Order
export const ORDER_URL = BASE_URL + '/order';
export const SECURE_ORDER_URL = BASE_URL + '/secure/order';
export const ORDER_LIST_URL = SECURE_ORDER_URL + '/orders';
export const ORDER_STATUS_URL = BASE_URL + '/orderStatus/list';
export const ORDER_FNB_STATUS_URL = BASE_URL + '/orderFnbStatus/list';
export const ADD_ORDER_URL = SECURE_ORDER_URL + '/addUpdate';
export const NOT_NOTIFIED_ORDER_URL = SECURE_ORDER_URL + '/notNotifiedOrders';
export const UPDATE_NOTIFIED_ORDER_URL =
  SECURE_ORDER_URL + '/updateOrdersAsNotified';
export const CANCEL_ORDER_URL = SECURE_ORDER_URL + '/cancelOrderByCustomer';
export const ADD_NEW_ORDER = ORDER_URL + '/addNewOrder';
export const GET_INIT_EXPECTED_DATE = ORDER_URL + '/getInitialExpectedDate';
export const VALIDATE_EXPECTEDDATE = ORDER_URL + '/validateExpectedDate';
export const CALCULATE_ORDER_PRICE = ORDER_URL + '/calculateOrderPrice';
export const ORDER_HISTORY = ORDER_URL + '/orders';
export const CANCEL_ORDER = ORDER_URL + '/cancelOrder';
export const ORDER_DETAIL = ORDER_URL + '/getOrder';

// Payment
export const PAY_STRIPE_URL = BASE_URL + '/stripeREST/pay';
export const CONFIRM_PAYMENT_STRIPE_URL =
  BASE_URL + '/stripeREST/confirmPayment';
export const PAYMENT_URL = BASE_URL + '/secure/payment';
export const PAYMENT_STATUS_URL = PAYMENT_URL + '/paymentStatus';
export const GET_QR_CODE_URL = `${API_BASE_URL}/ws/crm/payment/sgqr/getDynamicQR`;

// History
export const LOYAL_HISTORY_URL = BASE_URL + '/loyalty/getLoyaltyHistory';
export const WALLET_HISTORY_URL = BASE_URL + '/wallet/getWalletHistory';

// Master card
export const CREATE_MASTERCARD_SESSION =
  BASE_URL + '/mastercardREST/order/session/createCheckoutSession';

// ADYEN
export const GET_ADYEN_PAYMENT_METHODS = BASE_URL + '/adyenREST/paymentMethods';
export const INIT_ADYEN_PAYMENT = BASE_URL + '/adyenREST/payment';
