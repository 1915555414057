import React, { useEffect, useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { NAVIGATION } from '../../constants';
import reactLocalStorage from '@Helper/localStorage';

interface NavigationMenusProps {
  isAuth: boolean;
  closeNavigation: () => void;
}
const NavigationMenus: React.FC<NavigationMenusProps> = ({
  isAuth,
  closeNavigation,
}) => {
  const [currentRestaurantId, setCurrentRestaurantId] = useState<string>('');
  const { restid } = useParams<any>();
  const currentRestaurant = reactLocalStorage.getObject('current-restaurant');

  useEffect(() => {
    setCurrentRestaurantId(currentRestaurant.restid || restid);
  }, [currentRestaurant.restid, restid]);

  const homeRestaurantLink = useMemo(
    () => (currentRestaurantId ? `/restaurant/${currentRestaurantId}` : '/'),
    [currentRestaurantId],
  );

  const menuItems = [];
  for (let index = 0; index < NAVIGATION.menus.length; index++) {
    const menu = NAVIGATION.menus[index];
    if (menu.requiredAuth && !isAuth) continue;
    menuItems.push(
      <Link
        key={index}
        to={menu.id === 1 ? homeRestaurantLink : menu.link}
        className="navbar-item has-text-link"
        onClick={closeNavigation}
      >
        {menu.icon && (
          <>
            <i className={menu.icon} />
            &nbsp;
          </>
        )}
        {menu.text}{' '}
        {menu.badge && (
          <span className="w3-badge w3-green has-background-danger">
            {menu.badge}
          </span>
        )}
      </Link>,
    );
  }
  return <div className="navbar-start">{menuItems}</div>;
};

export default NavigationMenus;
