import React, { Children, useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { translations } from '@Locales/i18n';
import { isEmpty, isNil } from 'lodash';
import { Customer } from '@Models/Customer/interfaces';
import { menuMap } from '@Helper/id';

import { loadUserCredentials } from './helper/userLocalStorage';
import { CreateNotification, Notification } from '@Models/App/interfaces';
import NotificationItem from './components/Notification';
import { getTitle } from './helper/navigationBarTitle';
import reactLocalStorage from './helper/localStorage';
import reactSessionStorage from './helper/sessionStorage';
import { Restaurant } from '@Models/Restaurant/interfaces';
import { CartItem } from '@Models/Cart/interfaces';
import { LOCALES } from '@Constants/constants';
import { apiClient } from '@Network/API';

const useAxiosLoader = () => {
  const [axiosError, setAxiosError] = useState(null);

  const interceptors = {
    error: (error: any) => {
      setAxiosError(error);
      setTimeout(() => {
        setAxiosError(null);
      }, 3000);
      return error;
    },
  };

  useEffect(() => {
    const resInterceptor = apiClient.interceptors.response.use(
      undefined,
      interceptors.error,
    );
    return () => {
      apiClient.interceptors.response.eject(resInterceptor);
    };
  }, [interceptors]);

  return [axiosError];
};

const createNotificationData = ({
  icon,
  type,
  title,
  message,
  overlay,
}: CreateNotification) => {
  const notification: Notification = {
    icon,
    message: message || '',
    title,
    overlay,
    type,
  };
  return notification;
};

const customer: Customer = {};
const currentRestaurant: Restaurant = {};
const cart: CartItem[] = [];

const defaultState = {
  locale: LOCALES.en_US,
  setLocale: (locale: string) => {},
  cart,
  customer,
  currentRestaurant,
  navBarTitle: '',
  cartAddItem: (item: CartItem) => {},
  cartUpdateItem: (item: CartItem) => {},
  cartRemoveItem: (item: CartItem) => {},
  cartClear: () => {},
  updateNavBarTitle: () => {},
  setCurrentRestaurant: (restaurant: Restaurant) => {},
  setLoggedInCustomer: (customer: Customer) => {},
  showNotification: (
    firstParam: number | CreateNotification,
    icon?: string,
    message?: string,
    type?: string,
    overlay?: boolean,
  ) => {},
  showConfirmNotification: (time: number, notification: Notification) => {},
  hideNotification: () => {},
};

const AppContext = React.createContext(defaultState);

function AppProvider(props: any) {
  const [locale, setLocale] = useState(defaultState.locale);
  const [navBarTitle, setNavBarTitle] = useState<string>('');
  const [cart, setCart] = useState<CartItem[]>(defaultState.cart);
  const [customer, setCustomer] = useState<Customer>(defaultState.customer);
  const [currentRestaurant, setCurrentRestaurant] = useState<Restaurant>(
    defaultState.currentRestaurant,
  );
  const [notification, setNotification] = useState<Notification | undefined>();
  const [axiosError] = useAxiosLoader();
  const { t } = useTranslation();

  const handleSetRestaurant = useCallback((restaurant: Restaurant) => {
    let menuIds: number[] = [];
    if (restaurant?.outletMenus && restaurant.outletMenus.length > 0) {
      menuIds = restaurant.outletMenus.map((o) => o.menuId as number);
    }

    if (!isNil(restaurant?.inShopMenuId)) {
      menuIds = [...menuIds, restaurant?.inShopMenuId];
    }

    menuMap.saveIds(menuIds);
    setCurrentRestaurant(restaurant);
  }, []);

  const setCartData = (items: CartItem[]) => {
    setCart(items);
    reactSessionStorage.setObject('current-cart', items);
  };

  const cartAddItem = (item: CartItem) => {
    setCartData([...cart, item]);
  };
  const cartUpdateItem = (item: CartItem) => {
    const nextCart = cart.map((cartItem) => {
      if (cartItem.cartItemId === item.cartItemId) {
        return item;
      }
      return cartItem;
    });
    setCartData(nextCart);
  };
  const cartRemoveItem = (item: CartItem) => {
    const nextCart = cart.filter((f) => f.cartItemId !== item.cartItemId);
    setCartData(nextCart);
  };

  const cartClear = () => {
    setCartData([]);
    reactLocalStorage.delete('current-order');
  };

  const updateNavBarTitle = () => {
    const title = getTitle();
    setNavBarTitle(title);
  };

  const setLoggedInCustomer = (input: Customer) => {
    setCustomer(input);
  };

  const DEFAULT_NOTIFICATION_DELAY = 3e3;
  const showNotification = (
    firstParam: number | CreateNotification = DEFAULT_NOTIFICATION_DELAY,
    icon?: string,
    message?: string,
    type?: string,
    overlay?: boolean,
  ) => {
    let notification: Notification;
    let time;

    if (typeof firstParam === 'number') {
      time = firstParam;
      notification = createNotificationData({
        icon: icon as string,
        type: type || '',
        message: message as string,
        overlay,
      });
    } else {
      time = DEFAULT_NOTIFICATION_DELAY;
      notification = createNotificationData(firstParam);
    }

    setNotification(notification);
    if (time > 0) {
      setTimeout(() => {
        if (notification) hideNotification();
      }, time);
    }
  };
  const showConfirmNotification = (time = 3000, notification: Notification) => {
    setNotification(notification);
    if (time > 0) {
      setTimeout(() => {
        if (notification) hideNotification();
      }, time);
    }
  };

  const hideNotification = () => {
    setNotification(undefined);
  };

  const hideNotificationOnSwipe = () => {
    setTimeout(() => {
      if (notification) hideNotification();
    }, 500);
  };

  useEffect(() => {
    const storedUser = loadUserCredentials();
    if (storedUser && storedUser.customer) {
      setLoggedInCustomer(storedUser.customer);
    }

    if (!Object.keys(currentRestaurant).length) {
      const currentRestaurant = reactLocalStorage.getObject(
        'current-restaurant',
      );
      if (currentRestaurant.restid) {
        const _restaurant = reactLocalStorage.getObject(
          currentRestaurant.restid,
        );
        if (_restaurant && Object.keys(_restaurant).length) {
          handleSetRestaurant(_restaurant);
        }
      }
    }
    const currentCart = reactSessionStorage.getObject('current-cart');
    if (currentCart && !isEmpty(currentCart)) {
      setCart(currentCart);
    }
    // eslint-disable-next-line
  }, [currentRestaurant]);

  useEffect(() => {
    if (axiosError === 'NETWORK_ERROR') {
      showNotification(
        3000,
        'fas fa-exclamation-triangle',
        t(translations.Global.COMMON_ERROR),
        'is-danger',
      );
    }
    // eslint-disable-next-line
  }, [axiosError]);

  return (
    <AppContext.Provider
      value={{
        locale,
        cart,
        customer,
        currentRestaurant,
        navBarTitle,
        setLocale,
        cartAddItem,
        cartUpdateItem,
        cartRemoveItem,
        cartClear,
        setCurrentRestaurant: handleSetRestaurant,
        updateNavBarTitle,
        setLoggedInCustomer,
        showNotification,
        showConfirmNotification,
        hideNotification,
      }}
    >
      {notification && (
        <NotificationItem
          notification={notification}
          onSwipe={hideNotificationOnSwipe}
        />
      )}
      {Children.toArray(props.children)}
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };
