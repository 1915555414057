import { TKeyValue } from '@Models/App/types';
import { loadUserCredentials } from '../../helper/userLocalStorage';
export function addAccessToken(initialConfig: any) {
  const config = initialConfig;

  const account = loadUserCredentials();
  let authorizationHeader: TKeyValue = {};
  if (account && account.token) {
    authorizationHeader = {
      ...authorizationHeader,
      'X-Auth-Token':
        (config.data && config.data.bearerToken ? 'Bearer ' : '') +
        account.token,
    };
    if (config.data && config.data.bearerToken) {
      delete config.data.bearerToken;
    }
  }
  config.headers = { ...config.headers, ...authorizationHeader };
  return config;
}

export function onRejected(error: any) {
  return Promise.reject(error);
}
